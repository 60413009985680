import { request } from '../request';

// 创建油田
const oilfieldCreate = function (params) {
  return request({
    url: 'api/oilfield/create',
    method: 'post',
    data: params
  })
};
// 油田列表
const oilfieldList = function (params) {
  return request({
    url: 'api/oilfield/list',
    method: 'post',
    data: params
  })
};
// 油田基本信息
const oilfieldGet = function (params) {
  return request({
    url: 'api/oilfield/get',
    method: 'post',
    data: params
  })
};
// 修改油田
const oilfieldUpdate = function (params) {
  return request({
    url: 'api/oilfield/update',
    method: 'post',
    data: params
  })
};
// 公司列表
const companyList = function (params) {
  return request({
    url: 'api/company/list',
    method: 'post',
    data: params
  })
};
// 用户个人基本信息
const userGet = function (params) {
  return request({
    url: 'api/user/get',
    method: 'post',
    data: params
  })
};
// 获取省列表
const province = function (params) {
  return request({
    url: 'api/oilfield/province',
    method: 'post',
    data: params
  })
};
const city = function (params) {
  return request({
    url: 'api/oilfield/city',
    method: 'post',
    data: params
  })
};
const county = function (params) {
  return request({
    url: 'api/oilfield/county',
    method: 'post',
    data: params
  })
};
export default {
  oilfieldCreate,
  oilfieldList,
  oilfieldGet,
  oilfieldUpdate,
  companyList,
  userGet,
  province,
  city,
  county
}
