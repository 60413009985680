<template>
  <div class="page">
    <base-left-nav-bar :activeIndex="activeIndex"></base-left-nav-bar>
    <div class="main">
      <base-top-nav-bar></base-top-nav-bar>
      <div class="mian-wrapper">
        <base-page-header
          ><span slot="r"
            ><slot>油田管理 — 油田列表</slot></span
          ></base-page-header
        >
        <div class="main-manager">
          <div class="header-serach">
            <div class="left">
              <button
                type="button"
                class="add-btn"
                v-show="authFlag.OILFIELD_CREATE"
                @click="go('/OilfieldCreate')"
              >
                ＋ 创建油田
              </button>
              <span class="text">按内容筛选：</span>
              <el-select
                v-model="dataSql.companyid"
                placeholder="请选择客户公司"
                @change="companySort"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <button type="button" class="reset-btn" @click="resetSort">
                清空筛选
              </button>
            </div>
          </div>
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="headerCellStyle"
            :cell-style="cellStyle"
            @sort-change="sortChange"
          >
            <el-table-column
              prop="oilfieldid"
              label="序号"
              min-width="80"
              sortable="'custom'"
            >
            </el-table-column>
            <el-table-column
              label="油田全名"
              min-width="128"
              sortable="'custom'"
            >
              <template slot-scope="scope">
                <span style="color: #409eff">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nickname"
              label="油田简称"
              min-width="128"
              sortable="'custom'"
            >
            </el-table-column>
            <el-table-column
              prop="adcode"
              label="地区号"
              min-width="128"
              sortable="'custom'"
            >
            </el-table-column>
            <el-table-column
              prop="company_info"
              label="公司简称"
              min-width="128"
              sortable="'custom'"
            >
            </el-table-column>
            <el-table-column
              prop="manager_info"
              label="油田负责人"
              min-width="128"
              sortable="'custom'"
            >
            </el-table-column>
            <el-table-column prop="date" label="创建时间" min-width="128">
            </el-table-column>
            <el-table-column label="操作" min-width="240">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-show="authFlag.OILFIELD_MODIFY"
                  @click="
                    goModify(scope.row.oilfieldid, '/oilfieldModifyBasic')
                  "
                  >修改基本信息 |</el-button
                >
                <el-button
                  type="text"
                  @click="goRelation(scope.row, '/userList')"
                  >用户列表 |</el-button
                >
                <el-button
                  type="text"
                  @click="goRelation(scope.row, '/machineList')"
                  >设备列表</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.curPage"
            :page-sizes="[20, 40, 60]"
            :page-size="dataSql.count"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLeftNavBar from 'components/base-nav-bar/base-left-nav-bar.vue'
import BaseTopNavBar from 'components/base-nav-bar/base-top-nav-bar.vue'
import BasePageHeader from '../../components/base-page-header/base-page-header.vue'

import OilfieldJS from 'network/oilfield/oilfield.js'
import CommonJS from 'assets/js/common.js'

export default {
  components: {
    BaseLeftNavBar,
    BaseTopNavBar,
    BasePageHeader
  },
  data() {
    return {
      activeIndex: 2,
      authFlag: {},
      page: {
        curPage: 1,
        total: 0
      },
      dataSql: {
        session_id: '',
        companyid: '',
        oilfieldid: '',
        deviceid: '',
        count: 20,
        page: 0,
        sort: '',
        desc: false // 默认升序false
      },
      phone: '',
      companyOptions: [],
      tableData: []
    }
  },
  watch: {},
  methods: {
    /**
     * 事件监听
     */
    // 跳转到创建新用户界面
    go(v) {
      this.$router.push(v)
    },
    // 跳转到设置身份权限页面
    goRelation(row, path) {
      this.$router.push({
        path: path,
        query: { oilfieldid: row.oilfieldid, companyid: row.companyid }
      })
    },
    // 跳转到修改用户信息界面
    goModify(oilfieldid, path) {
      this.$router.push({
        path: path,
        query: { oilfieldid: oilfieldid }
      })
    },
    // 监听关联关系id进行筛选，没有就默认加载油田列表
    addRelationListen() {
      const companyid = this.$route.query.companyid
      const oilfieldid = this.$route.query.oilfieldid
      const deviceid = this.$route.query.deviceid
      if (companyid) {
        this.dataSql.companyid = parseInt(companyid)
        this.getOilfieldList()
        return
      }
      if (oilfieldid) {
        this.dataSql.oilfieldid = parseInt(oilfieldid)
        this.getOilfieldList()
        return
      }
      if (deviceid) {
        this.dataSql.deviceid = parseInt(deviceid)
        this.getOilfieldList()
        return
      }
      this.getOilfieldList()
    },
    handleSizeChange(val) {
      this.dataSql.count = val
      this.getOilfieldList()
    },
    handleCurrentChange(val) {
      this.dataSql.page = val - 1
      this.getOilfieldList()
    },
    resetSort() {
      this.dataSql.companyid = ''
      this.getOilfieldList()
    },
    // 重置关联关系参数
    resetRelationParam() {
      this.dataSql.companyid = ''
      this.dataSql.oilfieldid = ''
      this.dataSql.deviceid = ''
    },
    // 按内容筛选-公司
    companySort(v) {
      this.phone = ''
      this.dataSql.companyid = v
      this.getOilfieldList()
    },
    sortChange(column) {
      console.log(column)
      // prop：排序那列表格的字段名
      // order：排序的方式 ascending == 升序 descending == 降序 null == 不排序
      this.dataSql.sort = column.prop
      this.dataSql.desc = column.order == 'ascending'
      this.getOilfieldList()
    },
    /**
     * 网络请求
     */
    // 按手机号查询
    searchUserListByPhone() {},
    // 获取油田列表
    getOilfieldList() {
      this.dataSql.session_id = this.$store.state.loginInfo.session_id
      OilfieldJS.oilfieldList(this.dataSql).then((res) => {
        if (res.code == 0) {
          this.tableData = res.oilfield_list
          this.page.total = res.total_count
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取公司列表
    getCompanyList() {
      const param = {
        session_id: this.$store.state.loginInfo.session_id,
        count: 99,
        page: 0
      }
      OilfieldJS.companyList(param).then((res) => {
        if (res.code == 0) {
          const arr = []
          for (const i of res.company_list) {
            const obj = { value: '', label: '' }
            obj.value = i.companyid
            obj.label = i.name
            arr.push(obj)
          }
          this.companyOptions = arr
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    /**
     * 其它方法
     */
    // 查看权限
    authCheck() {
      this.authFlag = CommonJS.authCheck(this.$store.state.loginInfo.gtype_name)
    },
    headerCellStyle() {
      return {
        height: '41px',
        'line-height': '41px',
        'text-align': 'center',
        'font-size': '16px',
        'font-family': 'Source Han Sans CN',
        color: '#333333',
        background: '#F4F5F7'
      }
    },
    cellStyle() {
      return {
        'text-align': 'center'
        // color: '#000000',
        // 'font-size': '14px',
        // 'font-family': 'Source Han Sans CN'
      }
    }
  },
  mounted() {
    this.authCheck()
    this.addRelationListen()
    this.getCompanyList()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: 1300px;
  min-height: 100vh;
  // min-height: 1080px;
}
.main {
  width: 1698px;
  height: auto;
  background: #ebecee;
}
.mian-wrapper {
  padding: 28px; // 边距
  width: 1698px;
  height: 879px;
  .main-manager {
    position: relative;
    width: 1642px;
    height: 82.5vh;
  }
  .header-serach {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 1642px;
    height: 96px;
    background: #fff;
    .left {
      button:first-child {
        margin-left: 24px;
      }
      .text {
        margin-left: 26px;
        width: auto;
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      .el-select {
        margin-left: 4px;
        ::v-deep {
          .el-input__inner {
            width: 240px;
          }
        }
      }
    }
    .add-btn {
      width: 114px;
    }
    .reset-btn {
      margin-left: 20px;
      width: 90px;
    }
    .add-btn,
    .reset-btn {
      height: 40px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      background: rgba(1, 158, 229, 0.6);
      border: none;
      &:active {
        background: rgba(1, 158, 229, 1);
      }
    }
  }
  ::v-deep {
    .el-table__body-wrapper {
      max-height: 61vh;
      overflow-y: scroll;
    }
    .el-input__inner {
      border-radius: 0px;
    }
    .el-pagination {
      position: absolute;
      bottom: 0px;
      right: 24px;
    }
  }
}
</style>
