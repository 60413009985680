import { render, staticRenderFns } from "./OilfieldList.vue?vue&type=template&id=e19156ca&scoped=true&"
import script from "./OilfieldList.vue?vue&type=script&lang=js&"
export * from "./OilfieldList.vue?vue&type=script&lang=js&"
import style0 from "./OilfieldList.vue?vue&type=style&index=0&id=e19156ca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e19156ca",
  null
  
)

export default component.exports